import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicy = () => {
  const lang = "en"

  return (
    <Layout lang={lang} translation="/pl/polityka-prywatnosci/">
      <SEO title="Privacy Policy" />

      <section className="default-content">
        <h1>Privacy Policy</h1>
        <h2>Basic information on the processing of personal data</h2>
        <h4>1. Data Administrator </h4>
        <p>
          TREIMORFA PROJECT SP. Z O.O. Mogilska 16 / 10, 31-516 Kraków, Polska
          KRS 0000249132, NIP 6762311871 (further called: TREIMORFA PROJECT).
        </p>
        <h4>2. Contact details</h4>
        <p>
          You can contact us at TREIMORFA PROJECT via email:
          sekretariat@gdkgroup.pl, or telephone number: +48 690 888 343 or in
          writing: TREIMORFA PROJECT SP. Z O.O. Mogilska 16 / 10, 31-516 Kraków,
          Polska KRS 0000249132, NIP 6762311871. TREIMORFA PROJECT did not
          appoint data protection inspector.
        </p>
        <h4>3. The purposes of processing and its legal basis</h4>
        <ul>
          <li>
            Your data will be processed in order for us to be able to provide
            you with additional information on the job offer. The provision of
            data is voluntary and allows the provision of marketing information
            - the provision of data is your consent, provided that this consent
            has been given by you. If you do not give your consent, your
            personal data will not be processed for this purpose.
          </li>
          <li>
            Your data will be processed for direct marketing regarding TREIMORFA
            PROJECT own products and services - the legal basis for data
            processing is your consent, provided that this consent has been
            given by you. If you do not give your consent, your personal data
            will not be processed for this purpose.
          </li>
          <li>
            Your data will be processed in order to provide services such as,
            for example, participation in a webinar - the legal basis for data
            processing is your consent, provided that this consent has been
            given by you. If you do not give your consent, your personal data
            will not be processed for this purpose
          </li>
          <li>
            Your data will be processed in order to carry out the recruitment
            process for a given position - the legal basis for data processing
            is your consent given when completing the application form
            (application for a given position).
          </li>
        </ul>
        <h4>4. The period for which the data will be stored</h4>
        <p>
          The data will be processed for 2 years or until you withdraw your
          consent to the processing of your personal data for marketing
          purposes..
        </p>
        <h4>5. Data recipients</h4>
        <p>
          Your data may be transferred to entities processing personal data on
          behalf of TREIMORFA PROJECT, in particular to IT services’ providers
          and accounting offices - where such entities will process data on the
          basis of an agreement with TREIMORFA PROJECT and only in accordance
          with TREIMORFA PROJECT instructions.
        </p>
        <h3>6.Data transfer outside the European Economic Area</h3>
        <p>
          Your data will not be transferred to recipients located in countries
          outside of the European Economic Area.
        </p>
        <h3>7.Your rights regarding the personal data:</h3>
        <p>You have the following rights:</p>
        <ul>
          <li>have the full access to your data</li>
          <li>request for us to edit your data</li>
          <li>request for us to completely delete your data</li>
          <li>request to limit the processed data,</li>
          <li>remove your consent for our data processing ,</li>
          <li>
            the right to transfer personal data, i.e. to receive your personal
            data from TREIMORFA PROJECT in a file in a commonly used format,
          </li>
          <li>
            lodge a complaint with the supervisory body dealing with the
            protection of personal data.
          </li>
        </ul>
        <p>
          In order to use any of the above-mentioned rights, please contact
          TREIMORFA PROJECT. This can be done, for example, by sending an
          application to the TREIMORFA PROJECT office address or by e-mail to
          the following address sekretariat@gdkgroup.pl
        </p>
        <h3>8. Voluntary data provision</h3>
        <p>
          Providing personal data for marketing purposes is voluntary. Providing
          personal data for the purpose of considering a possible complaint is
          voluntary, however, failure to do so will make it impossible to accept
          and consider your complaint.
        </p>
        <h3>9. Data protection measures taken </h3>
        <ul>
          <li>
            Data protection is carried out in accordance with the requirements
            of generally applicable law, and its storage takes place on the
            secured servers.
          </li>
          <li>
            We respect the right to privacy and we care about data security. For
            this purpose, secure communication encryption protocol (SSL).
          </li>
          <li>
            We have implemented data encryption and access control, thanks to
            which we can minimize the effects of a possible breach of data
            security.
          </li>
        </ul>
        <h3>10.Cookie files</h3>
        <ul>
          <li>
            unitycentre.pl uses cookies. These are small text files sent by a
            web server and stored by the web browser.. When the browser
            reconnects with the website, the website recognizes the type of
            device from which the user connects. These parameters allow the
            information contained in them to be read only by the server that
            created them. Therefore, cookies facilitate the use of previously
            visited websites. The collected information relates to the IP
            address, type of browser used, language, type of operating system,
            Internet service provider, information about the time and date,
            location and information sent to the website via the contact form.
          </li>
          <li>
            The collected data is used to monitor and check how users use our
            websites to improve its functioning, ensuring more effective and
            problem-free navigation. We monitor user information using the
            Google Analytics tool that records user behavior on the website.
            Cookies identify the user, which allows the content of the website
            he/she uses to be adapted to his/her needs. By remembering his/her
            preferences, it enables the appropriate adjustment of advertisements
            addressed to him/her. We use cookies to guarantee the highest
            standard of convenience for our website, and the collected data is
            used only inside TREIMORFA PROJECT in order to optimize our
            activities.
          </li>
          <li>Our website uses cookies:</li>
          <ul>
            <li>
              “Obligatory” cookie files, that enable the access to tools from
              services within the website, eg authentication cookie files that
              are used for authentication services within the website;
            </li>
            <li>
              cookies used to ensure security, e.g. used to detect fraud in the
              field of authentication within the website;
            </li>
            <li>
              "performance" cookies, enabling the collection of information on
              the use of the website pages;
            </li>
            <li>
              "Advertising" cookies, enabling us to provide advertising content
              more tailored to the interests of our website’s users.
            </li>
          </ul>
          <li>
            The user may at any time disable or restore the option of collecting
            cookies by changing the settings in the web browser. The cookie
            management instructions are available at:
            http://www.allaboutcookies.org/manage-cookies
          </li>
          <li>
            Additional personal data, such as e-mail address, are collected only
            in places where the user has expressly consented to it by completing
            the form. We store and use the above data only for the purposes
            necessary to enable specific functions on our website..
          </li>
        </ul>
        <p>
          Your can read more about cookies in our{" "}
          <Link to="/cookies-policy/">Cookies Policy</Link>.
        </p>
      </section>
    </Layout>
  )
}

export default PrivacyPolicy
